*,
:after,
:before {
  box-sizing: border-box;
}

:root {
  --main-color-pink: rgb(221, 41, 110);
  --main-color-pink-light: #e51c75;
  /* --main-color-gray: #4a515e; */
  --main-color-gray: #3b4f53;
  --main-color-pink-dark: #41092b;
  --main-color-black: #101522;
  --main-color-pink-transparent: rgba(221, 41, 101, 0.7);
  /* --main-color-pink-very-transparent: rgba(237, 30, 121, 0.4); */
  --main-color-dark-beach-very-transparent: rgba(215, 199, 179, 0.4);
  --main-color-black-transparent: rgba(16, 21, 34, 0.92);
  --main-color-beach-transparent: rgba(230, 220, 210, 0.75);
  --main-color-gray-brown: #f2ede8;
  --main-color-gray-brown-dark: #e1d5c6;
  --main-color-white: white;
  --page-background-color: var(--main-color-beach-transparent);
  /* background-color: var(--page-background-color); */
}

html {
  background-color: rgba(12, 28, 39 1);
}

html,
body,
#root {
  height: 100%;
  background-color: var(--main-color-gray-brown);
}

#root {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

body {
  margin: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-shadow: inset 0 0 0 8000px rgba(250, 250, 250, 1);

  font-family: "Font-Normal", sans-serif;
  font-weight: 300;
}

@font-face {
  font-family: "Font-Black";
  src: local("Montserrat-Black"), url(./resources/fonts/Montserrat-Black.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Font-Normal";
  src: local("Montserrat-Regular"), url(./resources/fonts/Montserrat-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Font-SemiBold";
  src: local("Montserrat-SemiBold"), url(./resources/fonts/Montserrat-SemiBold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html h1 {
  text-transform: uppercase;
  font-weight: 800;

  margin-top: 0.5em;
}

.component-container {
  width: 100%;

  padding-top: 0em;
  box-sizing: border-box;
}

.presentation-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
  flex-direction: column;
}

.adress-wrapper p {
  margin-top: 0.7em;
  align-items: left;
  text-align: left;
  line-height: 0.1;
}

.presentation-paragraph {
  width: 70%;
  text-align: center;
  padding-bottom: 0em !important;
  margin-bottom: 0em !important;
}

.presentation-paragraph-2 {
  width: 75%;
  text-align: justify;
}

.landing-image-logo {
  content: url("resources/BrandImages/skarp-systems-small-logo-square-white-transparent.png");
  max-height: 16em;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landing-main {
  /* background-color: var(--main-color-gray-brown); */
  background-repeat: no-repeat;
  background-image: url("resources/symbol_beige_3200.png");
  background-position-x: 10em;
  background-position-y: 3em;
}

.news-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
}

.news-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 4em;
  padding-top: 4em;
}

.news-item {
  margin: 1em 2.5em 0.1em 0em;
}

.news-item p {
  color: black;
  font-weight: 600;
  transition: 0.4s;
  padding-top: 0.5em;
}

.news-item span {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--main-color-pink);
  transition: 0.4s;
}

.news-item:hover span {
  color: #0c1c27;
}

.news-item:hover p {
  color: var(--main-color-pink);
}

.news-item:hover .news-item-image {
  opacity: 0.8;
}

.news-item-image {
  border: 0.13em solid white;
  max-height: 13em;
  min-height: 13em;
  min-width: 13em;
  max-width: 13em;
  object-fit: cover;
  transition: 0.4s;
}

.news-post-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.news-post-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  align-items: flex-start;
}

.news-title {
  text-align: left !important;
  padding-top: 0.5em;
  padding-bottom: 0.2em !important;
  width: 100%;
  border-bottom: 0.3em solid var(--main-color-pink);
}

.date-string {
  justify-self: flex-start !important;
  align-self: flex-start;
  color: #0c1c27;
  font-weight: 500;
}

.author-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
  justify-self: flex-start;
  align-self: flex-start;
}

.author-image {
  height: 6em;
  width: 6em;
  border-radius: 50%;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.7em;
}

.author-name {
  font-weight: 600;
}

.post-info-paragraph {
  width: 100%;
  text-align: justify;
}

.post-page-image {
  width: 100%;
  margin-bottom: 2em;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
}

.landing-image-wrapper {
  height: 92vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("resources/hero-bg-1920.jpg");

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-origin: content-box;
  color: white;
}

.landning-image-content-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4em;
}

.landning-image-content-wrapper p {
  width: 60%;
  text-align: center;
  font-size: 1.4em;
  font-style: italic;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh;
  margin-bottom: 11em;
  width: 100%;
}

.loader {
  border: 5px solid #e1e1e1;
  border-radius: 50%;
  border-top: 5px solid var(--main-color-pink);
  width: 3.5em;
  height: 3.5em;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.justified-loader {
  margin-left: 5em;
  margin-top: 5em;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.up-down-arrow {
  color: var(--main-color-pink);
  background: green;
}

.scroll-down-arrow {
  color: var(--main-color-pink);
  background: transparent;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
  position: absolute;
  font-size: 2em;
  margin-top: 10em;
  cursor: pointer;
  -webkit-animation: MoveUpDown 2s linear infinite; /* Safari */
  animation: MoveUpDown 2s linear infinite;
}

/* Safari */
@-webkit-keyframes MoveUpDown {
  0% {
    padding-top: 3em;
  }
  50% {
    padding-top: 3.5em;
  }
  100% {
    padding-top: 3em;
  }
}

@keyframes MoveUpDown {
  0% {
    padding-top: 3em;
  }
  50% {
    padding-top: 3.5em;
  }
  100% {
    padding-top: 3em;
  }
}

.page-sect-landing-first-words-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  padding-top: 1.6em;

  justify-content: center;
  align-items: center;
}

.landing-first-words-wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  align-items: center;
  /* border: 5px solid var(--main-color-black); */
  border-radius: 25px;
  /* background-color: var(--main-color-gray-brown-dark); */
  font-family: Font-SemiBold;
}

.about-page-first-section-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background-color: gray; */
  /* border: 5px solid var(--main-color-black); */
  border-radius: 25px;
  height: auto;
  /* background-color: var(--main-color-gray-brown-dark); */
  font-family: Font-SemiBold;
}

.about-page-first-section-wrapper p {
  max-width: 80%;
  text-align: left;
  font-size: 1.2em;
  /* background-color: #dd296e; */
  text-align: left;
  text-justify: none;
  margin: 0.4em;
  border-radius: 25px;

  font-family: Font-SemiBold;
}

.about-page-first-section-wrapper h1 {
  font-size: 2em;
  color: var(--main-color-pink);
}

.landing-first-words-wrapper h1 {
  font-size: 3.2em;
  color: var(--main-color-pink);
}

.landing-first-words-wrapper p {
  text-align: left;
  font-size: 1.6em;
  text-align: justify;
  text-justify: none;
  margin: 0.4em;
  border-radius: 25px;

  font-family: Font-SemiBold;
  /* border: 3px solid var(--main-color-pink); */
}

/* LANDING
 * VAD VI GÖR | VI JOBBAR MED 
*/
.page-sect-work-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 3em;
}

.page-sect-val-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2em;
  padding-top: 2em;
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  /* border: 5px solid var(--main-color-pink); */

  /* background-image: url("resources/symbol_beige.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  /* background-color: darkorchid; */
}

.page-sect-val-wrapper h2 {
  margin: 0;
  font-size: 2em;
  font-family: "Font-SemiBold";
}

.work-item-wrapper {
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: left;

  /* background-color: blue; */
  margin-bottom: 1em;
}

.work-with-areas-wrapper .work-item-wrapper span {
  margin-top: 0.5em;
}

.work-icon-image {
  max-height: 3.8em;
  margin-right: 1em;
  object-fit: cover;
}

.work-with-us-contact-page-wrapper .work-item-wrapper {
  width: 90%;
}

.work-with-us-contact-page-wrapper .work-icon-image {
  margin-left: 2em;
}

.work-with-us-contact-page-wrapper .work-with-areas-wrapper .click-here-button {
  margin-left: 19% !important;
}

.work-label {
  color: black;
  font-weight: 700;
  padding-top: 0.5em;
  font-size: 1.5em;
  align-self: center;
}

.landing-work-label {
  color: white;
  font-weight: 700;
  padding-top: 0.5em;
  font-size: 1.5em;
  align-self: center;
}

.read-more-button {
  padding: 1.5em 3em 0.5em 3em;

  background-color: var(--main-color-gray);
  color: white;
  font-weight: bold;
  border-radius: 25px;

  transition: 0.4s;
  margin-top: 1.5em;
}

.news-item-container .read-more-button {
  margin-right: 1em;
}

.read-more-button:hover {
  background-color: #0c1c27;
  border: 1px solid #0c1c27;
  color: white;
}

.AnimatedShowMore__ToggleButton {
  width: 100%;
  height: 2em;
  text-align: center !important;
  color: var(--main-color-pink) !important;
  transition: 0.4s !important;
  font-weight: 600 !important;
  font-size: large !important;
  text-transform: uppercase !important;
}

.work-item-container .AnimatedShowMore__ToggleButton {
  width: 100%;
  height: 2em;
  text-align: center !important;
  color: var(--main-color-pink) !important;
  transition: 0.4s !important;
  font-weight: 600 !important;
  font-size: large !important;
  text-transform: uppercase !important;
}

/* .work-item-container .AnimatedShowMore__ToggleButton:hover {
  color: white !important;
} */

.core-values-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* background-color: white; */
  justify-content: center;
}

.page-sect-val-wrapper h2 {
  font-size: 3em;
  color: var(--main-color-pink);
}

.value-container {
  color: black;
  background-color: pink;

  text-align: justify;
  text-justify: none;
  padding: 2em;
  border: 2px;
  border-radius: 25px;
  margin-left: 2em;
  margin-right: 2em;
  font-size: 1.3em;
}

.CoreValueHeadline {
  font-family: "Font-Normal";
  line-height: 0em;
  color: black;
  font-size: 30px;
}
h3:after {
  content: "";
  display: block;
  border: 2px solid var(--main-color-pink);
}

.value-container h3 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 900;
  padding-bottom: 0em;
}

.about-skills-and-info-wrapper {
  width: 100%;
  background-repeat: no-repeat;
  background-image: url("resources/symbol_beige_3200.png");
  background-position-x: 10em;
  background-position-y: 5em;
}
/* 
.trow {
  display: flex;
}
.trow > div {
  padding: 15px;
}
.ttext {
  width: 66.6666%;
  color: #dd296e;
}
.timage {
  width: 33.333%;
}
.tcontainer .trow:nth-child(2n + 1) {
  flex-direction: row-reverse;
} */

.s-symbol-background {
  background-repeat: no-repeat;
  background-image: url("resources/symbol_beige_3200.png");
  background-position-x: -10em;
  background-position-y: 1em;
}

.anchor-link-offset {
  display: block;
  position: relative;
  top: -100px;
}

.we-work-with-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 4em;
  /* background-color: darkblue; */
  /* background-color: var(--main-color-gray-brown); */
}

.we-work-with-page-container h2 {
  margin-top: 2em;
}

.work-areas-wrapper {
  display: flex;
  /*flex-wrap: wrap;*/
  flex-direction: row;
  width: 80%;
  align-items: flex-start;
}

.work-item-container-wrapper {
  width: 100%;
}

.work-item-container {
  width: 100%;
  text-align: left;
  padding: 1em 3em 1em 3em;
}

.work-area-text p,
.work-area-text span {
  padding-top: 1em;
}

.button-wrapper-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-cards-wrapper {
  flex-wrap: wrap;
  display: flex;
  margin-top: 0em;
  /* background-color: blue; */
  justify-content: center;
}

.contact-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: var(--main-color-dark-beach-very-transparent);
  margin: 2em 4em 2em 4em;
  padding: 1em 2em 1em 2em;
}

.contact-icon-image {
  max-height: 6em;
  max-width: 6em;
  margin-right: 1em;
  margin-top: 0.2em;
  /* background-color: lightsalmon; */
}

.contact-card-text-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: lightpink; */
}

.contact-card-name {
  color: var(--main-color-pink) !important;
  font-size: 1.4em;
  font-weight: 600;
}

.contact-card-title {
  font-weight: 600;
}

.fa-icon {
  color: #0c1c27;
  padding-right: 0.2em;
  padding-top: 0.1em;
}

.contact-card-email a,
.contact-card-phone a {
  text-decoration: none;
  color: var(--main-color-gray);
  transition: 0.4s;
}

.contact-card-email a:hover,
.contact-card-phone a:hover {
  color: #0c1c27;
}

.contact-card-text-content-wrapper p {
  margin: 0.02em 0em;
  color: var(--main-color-gray);
}

.contact-form input,
.contact-form textarea,
.contact-form a {
  margin: 1em;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid transparent;
}

.contact-form a {
  padding: 0.5em 3em 0.5em 3em;
  background-color: transparent;
  color: white;
  border-radius: 25px;
  border: 2px solid white;
  transition: 0.4s;
  text-align: center;
}

.contact-form a:hover {
  background-color: var(--main-color-pink);
  text-decoration: none;
  border: 2px solid var(--main-color-gray);
}

.contact-card-text-content-wrapper .AnimatedShowMore__ToggleButton,
.contact-card-text-content-wrapper .AnimatedShowMore__ShadowOverlay {
  width: 50% !important;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.work-with-us-contact-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
}

.work-with-us-contact-page-wrapper .work-with-areas-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.work-with-us-contact-page-wrapper .work-with-areas-wrapper .click-here-button {
  margin-left: 30%;
}

.pulsing-logo {
  max-height: 5em;
  position: absolute;
  height: 50vh;
  content: url("resources/BrandImages/skarp-systems-small-logo-square-white-transparent.png");
}

.pulse {
  animation: pulse 5s infinite;
  margin: 0 auto;
  display: table;
  margin-top: 50px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

/********************************/
/* MEDIA QUERIES SECTION BELOW  */
/********************************/

@media only screen and (max-width: 800px) {
  html h1 {
    text-transform: uppercase;
    font-size: 1.6em;

    margin-top: 1.8em;
  }
  .scroll-down-arrow {
    margin-top: 8em;
    font-size: 1.5em;
  }
  .landing-image-logo {
    max-height: 6em;
  }
  .landing-image-wrapper p {
    font-size: 1.1em;
    text-align: center;
  }

  .landing-main {
    background-image: none;
  }
  .landing-image-wrapper {
    background-image: url("resources/hero-bg-640.jpg");
  }

  .page-sect-landing-first-words-wrapper {
    padding-top: 0em;
  }

  .landing-first-words-wrapper {
    width: 90%;
    padding-bottom: 2em;
  }

  .landing-first-words-wrapper h1 {
    /* text-align: center; */
    font-size: 3em;
  }

  .landing-first-words-wrapper p {
    text-align: center;
    font-size: 1.2em;
  }

  .page-sect-val-wrapper {
    padding-top: 0;
    margin-top: 0;
  }

  .page-sect-work-wrapper {
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 0em;
    width: 100%;
  }

  .presentation-wrapper {
    padding-bottom: 2em;
  }

  .core-values-wrapper {
    flex-direction: column;
    width: 100%;
  }
  .value-container {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 1em;
  }

  .presentation-paragraph {
    width: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .news-title-container-top {
    display: none;
  }
  .news-container {
    flex-direction: column;
  }
  .news-item-container {
    border-right: 0em;
    width: 100%;
  }
  .news-item {
    margin: 1em;
  }
  .read-more-button {
    padding: 0.5em 1.5em 0.5em 1.5em;
  }

  .contact-icon-image {
    max-height: 6em;
    max-width: 6em;
    margin-right: 1em;
    margin-top: 0.2em;
    margin-bottom: 1em;
  }
  .contact-card-name {
    justify-self: center;
    align-self: center;
    padding-bottom: 0.5em;
  }

  .contact-card-wrapper {
    flex-direction: column;
    margin: 1em 0 1em 0;
  }
  .work-with-us-contact-page-wrapper {
    padding-top: 0em;
  }

  .work-item-container {
    padding: 1em 0em 1em 0em;
  }
  .work-areas-wrapper {
    flex-direction: column;
  }
  .work-areas-wrapper .work-item-wrapper {
    justify-content: flex-start;
  }

  .news-post-wrapper {
    width: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .presentation-paragraph-2 {
    width: 80%;
    text-align: justify;
  }
}

@media only screen and (max-width: 400px) {
  .work-with-us-contact-page-wrapper .work-item-wrapper {
    width: 100%;
  }

  .work-with-us-contact-page-wrapper .work-with-areas-wrapper .click-here-button {
    margin-left: 16% !important;
  }

  .pulsing-logo {
    max-height: 3em;
    position: absolute;
    height: 25vh;
  }

  .news-item-container .read-more-button {
    margin-right: 0em !important;
    padding-left: 3em;
    padding-right: 3em;
  }
  .presentation-paragraph {
    text-align: center;
  }
}
